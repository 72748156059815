









.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #363049;
  border-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.5)
}

.btn-primary:hover {
  color: #fff;
  background: #433d56 !important;
  border-color: #f1cc4f;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0,0,0,0.5)
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background: #433d56 !important;
  border-color: #f1cc4f;
  box-shadow: 0 0 0 0.2rem rgba(4, 22, 41, 0.5);
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0,0,0,0.5)
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #363049;
  border-color: #363049;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #363049;
  border-color: #fff;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0px 5px 10px rgba(0,0,0,0.5)
}

.btn-secondary {
  color: #fff;
  background-color: #363049  !important;
  border-color: #363049 ;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #363049  !important;
  border-color: #363049 ;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #363049  !important;
  border-color: #363049 ;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #363049  !important;
  border-color: #363049 ;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745 !important;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838 !important;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838 !important;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34 !important;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496 !important;
  border-color: #117a8b !important;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496 !important;
  border-color: #117a8b !important;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b !important;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107 !important;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800 !important;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800 !important;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107 !important;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00 !important;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545 !important;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333 !important;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333 !important;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545 !important;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130 !important;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa !important;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea !important;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea !important;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa !important;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5 !important;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40 !important;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b !important;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b !important;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40 !important;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124 !important;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #fff !important;
    background-color: #976847   !important;
    border-color: #976847;
}

.btn-outline-primary:hover {
  color: #fff !important;
    background-color: #976847   !important;
    border-color: #976847;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #976847;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #976847    !important;
  border-color: #976847;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d    !important;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d   !important;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745   !important;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745   !important;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745   !important;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8   !important;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8   !important;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107   !important;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107   !important;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545   !important;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa   !important;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #976847;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

.btn{
  --bs-btn-border-width: 0px !important;
}
.btn-ekta {
    font-weight:400;
    color: #fff !important;
    background-color: #976847 !important;
    border-color: #976847 ;
    padding:5px 12px;
    border-width: 1px 
  }
  .bg-primary {
    --bs-bg-opacity: 1;
    background-color: #FDBB01 !important;
  }
  a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
    background-color: #f68433 !important;
  }
  .bg-secondary {
    --bs-bg-opacity: 1;
    background-color: #363049 !important;
}
.card {
background-color: transparent !important;
border: none;

}
 
@font-face {
  font-family: 'RM Neue';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('./assets/fonts/rm-neue/RMNeue-Light.woff2') format('woff2'),
    url('./assets/fonts/rm-neue/RMNeue-Light.woff') format('woff'),
    url('./assets/fonts/rm-neue/RMNeue-Light.otf') format('opentype');
}

@font-face {
  font-family: 'RM Neue';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url('./assets/fonts/rm-neue/RMNeue-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/rm-neue/RMNeue-SemiBold.woff') format('woff'),
    url('./assets/fonts/rm-neue/RMNeue-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'Lemon Milk';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('./assets/fonts/lemon-milk/LemonMilk-Regular.woff2') format('woff2'),
    url('./assets/fonts/lemon-milk/LemonMilk-Regular.woff') format('woff'),
    url('./assets/fonts/lemon-milk/LemonMilk-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Lemon Milk';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url('./assets/fonts/lemon-milk/LemonMilk-Medium.woff2') format('woff2'),
    url('./assets/fonts/lemon-milk/LemonMilk-Medium.woff') format('woff'),
    url('./assets/fonts/lemon-milk/LemonMilk-Medium.otf') format('opentype');
}
 
h1, h2, h3, h4, h5, h6 {
  font-family: Lemon Milk,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font-size: inherit !important;
  line-height: 1.5;
   
  margin-bottom: 1rem;
  margin-top: 0;
  font-weight:700 !important;
  text-transform: uppercase;
}
 
.hero-title{
  font-family: Lemon Milk,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font-size: 33.75px !important;
  text-align: center !important;

}
.hero-subtitle{
  font-family: RM Neue,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font-size: 22.75px !important;
  text-align: center !important;
  font-weight: 200;
}
h1{
  font-size: 33.75px !important;
  line-height: 1.5;
}
h2{
  font-size: 27.25px !important;
  line-height: 1.5;
}
h3{
  font-size: 20.25px !important;
  line-height: 1.5;
}
html {
  font-family: RM Neue,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font-size: 1.125rem;
  font-weight: 300;
   
  word-break: break-word;
}
.leading-relaxed {
  line-height: 1.625;
  font-size: 18px;
}
.text-lg {
  font-size: 1.125rem !important;
  font-weight: 100;
}
.text-ekta {
    margin-right: 2px;
    color: #976847 !important;
    border: none;
    padding: 5px 12px;
  }
  .show > .btn-ekta.dropdown-toggle:focus {
    box-shadow: none;
    color: #fff !important;
  }

  .btn-ekta:focus {
  
  box-shadow: none !important ;
  }
  .btn-ekta:hover {
    color: #fff;
    background-color: #d99d68  !important;
    border-color: #d99d68;
  }
  .btn-default, .btn-default:hover, .btn-default.focus, .btn-default:focus{
    border:none;
    background-color: transparent !important;
  }
  .btn-ekta:focus, .btn-primary.focus {
    color: #fff;
    font-weight:400;
    background-color: #d99d68  !important;
    border-color: #d99d68;
    box-shadow: 0 0 0 0.2rem #d99d68;
  }
  
  .btn-ekta.disabled, .btn-ekta:disabled {
    color: #fff;
    background-color: #d99d68  !important;
    border-color: #d99d68;
  }
  
  .btn-ekta:not(:disabled):not(.disabled):active, .btn-ekta:not(:disabled):not(.disabled).active,
  .show > .btn-ekta.dropdown-toggle {
    color: #fff;
    background-color: #976847  !important;
    border-color:#976847;
  }
  
  .btn-ekta:not(:disabled):not(.disabled):active:focus, .btn-ekta:not(:disabled):not(.disabled).active:focus,
  .show > .btn-ekta.dropdown-toggle:focus {
    box-shadow: none;
    color: #fff;
  }
  .custom-row{
    background-color: #f9f4e8;
  }
  .custom-slider{
    background-color: #363049;
    color:#f9f4e8;
    border-bottom:0.5px solid #f9f4e8 !important;
    border-right: 1px solid #f9f4e8 !important;
  }
  .custom-subheader{
    position: absolute; 
    top: 0px; 
    width: 100%; 
    height: 44px; 
    border-bottom:0.5px solid #976847;
    background-color: rgb(255, 255, 255); 
    color: rgb(51, 51, 51);
  }
  .table td {
    padding: 6px 10px;
    border: none;
    width: auto;
    background-color: #f9f4e8;
    border-left: 1px solid #976847;
    
  }
  .table thead th {
    border: 0px solid #976847;
    background-color: #d4ac8d;
    font-weight:normal;
    background: -moz-linear-gradient(142deg, rgba(193,151,119,1) 0%, rgba(212,172,141,1) 100%);
    background: -webkit-linear-gradient(142deg, rgba(193,151,119,1) 0%, rgba(212,172,141,1) 100%);
    background: linear-gradient(142deg, rgba(193,151,119,1) 0%, rgba(212,172,141,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c19777",endColorstr="#d4ac8d",GradientType=1);
    
  }
  thead, tbody, tfoot, tr, td, th {
    border-color: #976847;
    border-style: solid;
    border-width: 0px;
}
thead{
  border-top: 0px solid   #976847;;
  border-left: 1px solid #976847;
}
  td  {
     
    border-left: 1px solid #976847 !important;
}
  #header .pro-sidebar-inner .pro-sidebar-layout {
    background-color: inherit;
}
.pro-sidebar > .pro-sidebar-inner {
    background: inherit;
    height: 100%;
    position: relative;
    z-index: 101;
}
.pro-sidebar .pro-menu .pro-menu-item:first-child {
  font-size: 15px;
  margin: 0px 0px;
  padding-bottom: 0px;
  border-top: 0.5px solid #976847;
  border-bottom:0px solid #976847;
}
.pro-sidebar .pro-menu .pro-menu-item:last-child {
  font-size: 15px;
  margin: 0px 0px;
  padding-bottom: 0px;
  border-top: 0px solid #976847;
  border-bottom:0.5px solid #976847;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
  border-top: 0.5px solid #976847;
}

.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 15px;
  margin: 0px 0px;
  padding: 0px 0px;
  border-top: 0px solid #bcbcbc;
  border-bottom: 0px  solid #976847
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  border-top: 0.5px solid #976847;
  border-bottom: 0px solid #976847;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
color:#976847
}
#header .pro-sidebar-inner .pro-sidebar-layout .active {
    color: #fff !important;
    background-color:#976847 !important;
}
.badge{
    background-color:#976847 ;
    color:#fff ;

} 
.reverse{
    background-color:#fff !important;
    color:#d4ac8d !important;
}




.Toastify__toast-container {
    z-index: 9999999;
    -webkit-transform: translate3d(0px, 98px, 0px);
    position: fixed;
    padding: 4px;
   
    box-sizing: border-box;
    color: #fff;
  }
  
  .Toastify__toast-container--bottom-center{
    z-index: 9999999;
    -webkit-transform: translate3d(0px, 0px, 0px);
    position: fixed;
    padding: 4px;
   
    box-sizing: border-box;
    color: #fff;
  }
  .presaleBg{
    padding:60px 10px ;
    min-height:100vh
  }
  
  .btn-success {
    color: #fff;
    background-color: #427259 !important;
    border-color: #427259;
  }
  
  .btn-success:hover {
    color: #fff;
    background-color: #363049 !important;
    border-color: #363049;
  }
  
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #628070 !important;
    border-color: #628070;
    box-shadow: none;
  }
  
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #363049  !important;
    border-color: #363049 !important;
    box-shadow: none;
  }
  
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #363049  !important;
    border-color: #363049  !important;
  }
  
  .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
  .show > .btn-success.dropdown-toggle:focus {
    box-shadow:none;
  }
  
  .btn-danger:disabled, .btn-danger.disabled {
   
    cursor: not-allowed !important;
    pointer-events: auto;
  
  }
  .btn-danger, .btn-danger {
   
    background-color:#9f6541;
    border-color:#9f6541;
  
  }
  .btn-primary:disabled, .btn-primary.disabled {
   
    cursor: not-allowed !important;
    pointer-events: auto;
  
  }
  .btn-warning:disabled, .btn-warning.disabled {
   
    cursor: not-allowed !important;
    pointer-events: auto;
  
  }
  
  #myTab .nav-link.active {
    margin-bottom: -1px;
    
    background: #9f6541;
      color: #fff;
      font-weight: 400;
    margin-left: 10px;
    padding: 5px 10px;
    vertical-align: bottom;
    border: 1px solid transparent;
    border-bottom: 1px solid transparent;
    margin-top: 10px;
    margin-bottom: 2px;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  
  }
  #myTab .nav-link {
    margin-bottom: -1px;
    background: #fff;
    color: inherit;
    font-weight: 300;
    margin-left: 10px;
    padding: 5px 10px;
    vertical-align: bottom;
    border: 1px solid transparent;
    border-bottom: 1px solid transparent;
    margin-top: 10px;
    margin-bottom: 2px;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

  .devider{
    height:1px;
    background: #9f6541;
   
  }
  .App-header { 
    color: #976847 !important;
    margin-top:0px !important;
}
.bg-gradient-to-b{
  background-color: #363049 !important;
}
.nav-tabs .nav-item {
  pointer-events: auto;
}
input {
  border:1px solid #ccc;
}
 
.btn:hover {
  color: #212529;
  text-decoration: none;
  --bs-btn-border-width: 0px ;

}





.btn:hover {
  color: #fff;
  text-decoration: none;
  --bs-btn-border-width: 0px;
}


.footer {
  color: #ffffff !important;
  border-top: 0.5px solid #ffffff !important ;
}
footer {
  color: #ffffff !important;
  border-top: 0.5px solid #ffffff !important ;
}
.hero-description{
  font-size: 1.125rem;
    line-height: 1.75rem;
text-align: left;
}




.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #976847;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #976847;
}

.table tbody + tbody {
  border-top: 1px solid #976847;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #976847;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #976847;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item { 
  font-weight: bold;
}


.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  font-weight: bold;
}








 

#header-mobile {
  position: fixed;
  width: auto;
  top:0px;
  left:0px;
  z-index:999
}
/* #header .pro-sidebar {
  height: 100vh;
} */
#header-mobile .closemenu {
  color: #000;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 0px;
  cursor: pointer;
}

#header-mobile   .pro-sidebar.collapsed {
  width: 0px;
  min-width: 0px;
} 

#header-mobile .pro-sidebar-inner {
  background-color: inherit;
  color:inherit ; 
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}
/* #header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
} */
#header-mobile .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: #fff;
  font-weight: 300;
  cursor:pointer;

}
#header-mobile .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 0px;
}
#header-mobile .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: inherit;
  margin: 0px 0px;
  padding-top:10px ;
  padding-bottom:10px;
  font-weight: 300;
 
}
#header-mobile .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item > a {
  color: inherit;
  margin: 10px 0px;
  font-weight: 300;
}
#header-mobile .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: transparent;
  color: inherit;
  border-radius: 3px;
  height:auto;
  margin-right: 15px;
  margin-left: 0px;
   
}
#header-mobile .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
  color: #fff;
}
#header-mobile .pro-sidebar-inner .pro-sidebar-layout .active {
  color: #fff !important;
  background-color:#976847 !important;
  
}
#header .pro-sidebar-inner .pro-sidebar-layout {
  background-color: transparent;
}
#header-mobile .logo {
  padding: 20px;
}
 
 
  .md\:text-xs {
    padding:30px !important;
       
  }
 
  footer {
     margin-top: 0px !important;
  }
  header {
    margin-bottom: 0px !important;
     
}
.card-body{
  padding:5px !important;
  color: white !important;
}
.leading-relaxed{
  margin-top: 5px !important;
}
.max-w-10{
  width:100%  !important;
  max-width: 90rem !important;
}