#header {
    position: fixed;
    width: auto;
    top:0px;
    left:0px;
    z-index:999
  }
  /* #header .pro-sidebar {
    height: 100vh;
  } */
  #header .closemenu {
    color: #000;
    position: absolute;
    right: 0;
    z-index: 9999;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 22px;
    top: 0px;
    cursor: pointer;
  }
  
  #header   .pro-sidebar.collapsed {
    width: 0px;
    min-width: 0px;
  }

  #header .pro-sidebar.collapsed {
    width: 50px;
    min-width: 50px;
  }

  #header .pro-sidebar-inner {
    background-color: inherit;
    color:inherit ; 
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
  }
  /* #header .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;
  } */
  #header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
    font-size: 20px;
    padding: 0 20px;
    color: #fff;
    font-weight: 300;
    cursor:pointer;

  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0 0px;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: inherit;
    margin: 0px 0px;
    padding-top:10px ;
    padding-bottom:10px;
    font-weight: 300;
   
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item > a {
    color: inherit;
    margin: 10px 0px;
    font-weight: 300;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: transparent;
    color: inherit;
    border-radius: 3px;
    height:auto;
    margin-right: 15px;
    margin-left: 0px;
     
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
    color: #fff;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout .active {
    color: #240c3b !important; 
  }
  #header .pro-sidebar-inner .pro-sidebar-layout {
    background-color: transparent;
  }
  #header .logo {
    padding: 20px;
  }

  .show-mobile{
    display:none;
  }
  .show-mobile-inline{
    display:none !important;
  }
  .show-mobile-btn {
    display: none;
  }
  .hide-mobile{
    display: -webkit-inline-box;
  }
  
  .dropdown-item.active, .dropdown-item:active {
      color: #fff;
      text-decoration: none;
      background-color: #d09f70 !important;
  }
  
  .fa-stack {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 1em;
    line-height: 1em;
    vertical-align: middle;
}
  @media only screen and (max-width: 768px) {
    html {
      overflow: auto;
    }
    .show-mobile{
      display:grid;
    }
    .show-mobile-inline{
      display:inline-block !important;
    }
    .show-mobile-btn {
      display: inline-flex;
    }
    .hide-mobile{
      display:none !important;
    }
  }
   
  .ytp-ce-element.ytp-ce-element-show{
    z-index:-88888 !important;
  }
  .consol{
    font-weight:600;
  }
 
  .bg-dark {
    background-color: #44594e !important;
    color: #fff !important;
  }
  .bg-light{
    background-color:#fff  !important;
    color:#333  !important;
  }
  .react-grid-Container {
    background-color: inherit   !important;
    color: inherit !important;
  }
  .react-grid-Main {
    background-color: inherit !important;
    color: inherit !important;
  }
  .react-grid-Grid {
    background-color: inherit !important;
    color:inherit !important;
}
  .react-grid-Header {
    color:inherit !important;
    background-color:inherit !important;
  }

 
  .react-grid-HeaderCell, .react-grid-HeaderRow {
    color:inherit !important;
    background-color:inherit !important;
  }

  .react-grid-HeaderCell {
  color:inherit !important;
  background-color:inherit !important;
}
.react-grid-Cell {
  color:inherit !important;
  background-color:inherit !important;
}
.react-grid-Cell:not(.editing) .react-grid-Cell__value {
  color:#333 !important;
  background-color:inherit !important;

}

.modal.show .modal-dialog {
  top: 40%;
  color: #333;
  transform: none;
}
 
select {
  -webkit-appearance: none;
  appearance: none;
}

.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  content: "▼";
  font-size: 1rem;
  top: 6px;
  right: 10px;
  position: absolute;
}

.dropdown-toggle::after{
  float:right;
  margin:8px;
}
.custom-modal{
  padding:20px;
 
}
body {
  background-color:rgb(255, 255, 255);
  color:#000;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.mapboxgl-popup-content {
  position: relative;
  background: #dc3545;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  padding: 10px 10px 15px;
  pointer-events: auto;
}
.row{
  margin:0px !important;
}
.row {
  margin:0px !important;
}
.row > * {

    padding-right: 0;
    padding-left: 0;
   
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: visible;
  color:inherit;
  background-color:inherit !important;
}

.SandboxRoot.env-bp-550 .timeline-Tweet-text {
  margin-left: 44px;
  pointer-events: none !important;
}
.SandboxRoot.env-bp-550 .timeline-Tweet-text {
  margin-left: 44px;
  pointer-events: none !important;
}
.walletconnect-modal__base {
  -webkit-font-smoothing: antialiased;
  background: #ffffff;
  border-radius: 24px;
  color: #333 !important;
}

.walletconnect-modal__base {
  -webkit-font-smoothing: antialiased;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0 10px 50px 5px rgb(0 0 0 / 40%);
  font-family: ui-rounded, "SF Pro Rounded", "SF Pro Text", medium-content-sans-serif-font, -apple-system, BlinkMacSystemFont, ui-sans-serif, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-top: 41px;
  padding: 24px 24px 22px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  will-change: none !important;
  overflow: visible;
  transform: none !important;
  top: 10% !important;
  max-width: 333px !important;
  margin: auto;
}
.fa-1x{
  font-size:1em !important
}
.fa-2x{
  font-size:2em !important
}
/* .col-md-3 {
  flex: 0 0 auto;
  width: 100%;
}
.col-md-6 {
  flex: 0 0 auto;
  width: 100%;
}
.offset-md-3 {
  margin-left: 0%;
} */
@media (min-width: 812px){
   .offset-md-3 {
    margin-left: 25%;
  }  
  /* .col-md-3 {
      flex: 0 0 auto;
      width: 25%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
} */
}
#launcher{
  
  height:66px !important;
  bottom:-25px  !important;
  position:fixed !important;
 
   
  right:0px !important;
  margin:0px 20px !important;
}

#file-upload-button{
  width:100%   !important;
  display:block;
}
.csv-reader-input{
  overflow:hidden
}
::file-selector-button {
  width: 100%  !important;
  text-align: left;
  margin-right: 100% !important;
  font: inherit;
}
.select-checkbox-fa option::before {
  font-family: FontAwesome;
  content: "\f096";
  width: 1.3em;
  display: inline-block;
  margin-left: 2px;
}
.select-checkbox-fa option:checked::before {
  content: "\f046";
}
#triangle-topright {
  width: 0;
  height: 0;
  border-top: 10px solid rgb(0, 0, 0);
  border-left: 10px solid transparent;
}
.myplayer-class > div:first-child{
  margin:0 auto !important;
}
nav {
  position: sticky;
  top: 0; 
 
  z-index:2;
}
.react-player__play-icon{
  display:none;
}
.carousel-control-prev, .carousel-control-next {
  max-height:370px;
  display:none;
}
strong{
  font-weight:600;
}
.active-purple, .active-purple:active,  .active-purple:hover {
  color: #fff !important;
  text-decoration: none;
  background-color: #663397 !important;
}
p {
  margin-top: 0;
  margin-bottom: 0px;
}
#control-override  {
  background-color: transparent;
  border:none;
  opacity:0;
  content: "\f0a3";
  width:50px;
}
::file-selector-button {
   
  cursor: pointer;
}
table th.secondary, table td.secondary {
  background: #e2d5e5;
}
table th.third, table td.third {
  background: #d4e4f4;
}
table th.primary, table td.primary {
  background: #d7d8ec;
}
 
 
table th.right, table td.right {
  text-align: right;
}
table {
  border:none;
  border-collapse: separate;
  border-spacing: 2px;
}
.table-wrapper {
  font-size:11px;
  overflow-x: auto;
   
}
thead, tbody, tfoot, tr, td, th {
  
  padding: 3px 0.5em;
}
.active-button{
  background-color: #ebe7fb;
  color: #8125c2;
  padding:15px 0px;
  border-radius:5px
}
.default-button{
  background-color: #f2f2f2;
  color:#333;
  padding:15px 0px;
  border-radius:5px
}
.default-button:hover{
  background-color: #ebe7fb;
  color: #8125c2;
  padding:15px 0px;
  border-radius:5px
} 
select {

  /* styling */
  background-color: white;
  border: thin solid blue;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}


/* arrows */








select.minimal {
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}

select.minimal:focus {
  background-image:
    linear-gradient(45deg, rgb(80, 80, 80) 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, rgb(100, 100, 100) 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  border-color: rgb(51, 51, 51);
  box-shadow: none;
  outline: 0;
}

 
 